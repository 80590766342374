import React from "react"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import Layout from "../willoughby/layout"


export default function HomePage({ data }) {

  return (
    <Layout>
      <div className="content-container">
        <h1 className="l1-page-title">Lees-Craig</h1>
        <br />

        <div className='l1-grid-container'>
          <Link to='https://recipes.leescraig.com/' className='grid-card-container' >
            <div className='grid-card'>
                <StaticImage src='../images/links/banana_pancakes.jpg' alt='Pancakes' objectFit="cover" />
              <div className='card-title-module ctm-large-font'>
                <h3 className='card-title'>Recipes</h3>
              </div>
            </div>
          </Link>
          <Link to='https://travel.leescraig.com/' className='grid-card-container' >
            <div className='grid-card'>
                <StaticImage src='../images/links/middleeast-cover.jpg' alt='The domes of Qusayr Amra in eastern Jordan' objectFit="cover" />
              <div className='card-title-module ctm-large-font'>
                <h3 className='card-title'>Travel &amp; Photos</h3>
              </div>
            </div>
          </Link>
          <Link to='year-in-review' className='grid-card-container' >
            <div className='grid-card'>
                <StaticImage src='../images/year-in-review/2023/cropped/trip_1.jpg' alt='Our family recreating a wedding photo' objectFit="cover" />
              <div className='card-title-module ctm-large-font'>
                <h3 className='card-title'>Annual Letters</h3>
              </div>
            </div>
          </Link>
        </div>
        <br />
        <h2 >Links for us</h2>
        <div className='links-flexbox'>
          <Link to='https://privateemail.com/' className='link-card' >
            <svg viewBox="-5 -5 34 34" xmlns="http://www.w3.org/2000/svg" style={{backgroundColor: '#0284c7'}}><path d="m0 0h24v24h-24z" fill="none"/><path d="m20 4h-16c-1.1 0-1.99.9-1.99 2l-.01 12c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2v-12c0-1.1-.9-2-2-2zm0 4-8 5-8-5v-2l8 5 8-5z" fill="#fcfcfc"/></svg>
          </Link>
          <Link to='https://www.notion.so/lees-craig/' className='link-card' >
            <svg width="87" height="30" viewBox="-10 -10 49 50" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M1.805 1.294l16.6-1.226c2.039-.175 2.563-.057 3.845.875l5.299 3.733c.874.642 1.165.817 1.165 1.516v20.473c0 1.283-.466 2.042-2.097 2.158L7.34 29.99c-1.224.058-1.807-.117-2.448-.934L.99 23.982c-.7-.934-.99-1.633-.99-2.45V3.334c0-1.049.466-1.924 1.805-2.04z" fill="#fff"></path>
              <path fill-rule="evenodd" clip-rule="evenodd" d="M18.405.068l-16.6 1.226C.466 1.41 0 2.285 0 3.334v18.198c0 .817.29 1.516.99 2.45l3.902 5.074c.641.817 1.224.992 2.448.934l19.277-1.167c1.63-.116 2.097-.875 2.097-2.158V6.192c0-.663-.262-.854-1.033-1.42a85.473 85.473 0 01-.133-.096L22.25.943c-1.282-.932-1.806-1.05-3.845-.875zM7.776 5.857c-1.574.106-1.931.13-2.825-.597L2.678 3.452c-.231-.234-.115-.526.467-.584l15.958-1.166c1.34-.117 2.038.35 2.562.758l2.737 1.983c.117.059.408.408.058.408l-16.48.992-.204.014zM5.941 26.49V9.11c0-.759.233-1.109.931-1.168L25.8 6.834c.642-.058.932.35.932 1.108v17.264c0 .759-.117 1.401-1.165 1.459l-18.113 1.05c-1.048.058-1.513-.291-1.513-1.225zm17.88-16.448c.116.525 0 1.05-.525 1.11l-.873.173v12.832c-.758.408-1.456.641-2.039.641-.932 0-1.165-.292-1.863-1.166l-5.709-8.982v8.69l1.806.409s0 1.05-1.457 1.05l-4.017.233c-.117-.234 0-.817.407-.933l1.049-.291v-11.49L9.144 12.2c-.117-.525.174-1.283.99-1.342l4.31-.29 5.94 9.098v-8.049l-1.514-.174c-.117-.643.349-1.11.931-1.167l4.02-.234z" fill="#000"></path>
              </svg>
          </Link>
        </div>
      </div>
    </Layout>
  )
}

export function Head() {
  return (
    <title>Home - Lees-Craig Family</title>
  )
}